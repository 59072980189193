import { createMuiTheme } from '@material-ui/core/styles'

export const CognitoTheme =  createMuiTheme({
    overrides: {  
      MuiDialogContent: {
        root: {
          overflowY: 'hidden',
          padding: 3,
        }
      } ,  
      MuiDialog: {
        paperScrollPaper: {
          maxHeight: '90vh',
          maxWidth: '90vw',
        }                
      },
    },
  });  