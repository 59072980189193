import React from "react";
import '../styles/Logo.scss'

export default function Logo() {    
    const goHome = () => {
        let homeAddress = ""
        if ((process.env.REACT_APP_HOME) && (process.env.REACT_APP_HOME !== "")) {
            homeAddress = process.env.REACT_APP_HOME
        }

        window.location.href = homeAddress;     
    }    

    return (
        <div onClick={goHome} className="logoContainer">
            <img className="logo" src='/images/cognito_icon_large.png' alt="Cognito logo"/>        
            <div className="companyName">Cognito</div>
        </div>
    )
  }
  