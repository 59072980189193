import React from "react";
import '../styles/BackToTopButton.scss'
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// When the user scrolls down 20px from the top of the document, show the button
window.addEventListener('scroll', function(e) {
   const backToTopButton = document.getElementById("backToTopButton")
   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      backToTopButton.style.display = "block";
   }
   else {
      backToTopButton.style.display = "none";
   }
});

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
   document.body.scrollTop = 0; //For Safari
   document.documentElement.scrollTop = 0; //For Chrome, Firefox, IE and Opera
}

export default function BackToTopButton() {
 return (
   <button className="backToTopBtn" id="backToTopButton" onClick={topFunction}><FontAwesomeIcon size="2x" icon={faAngleDoubleUp} /></button>
 )
}
