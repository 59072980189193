import React, { useState, useEffect, useContext} from "react";
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag'
import { useQuery } from "@apollo/react-hooks";
import '../styles/CategoriesCloud.scss'
import Loader from './Loader'
import { ResourcesContext } from './ResourcesContext'
import { PAST_PAPERS_CATEGORY_NAME, QS_BY_TOPIC_CATEGORY_NAME, VIDEOS_CATEGORY_NAME } from './Constants';

const CATEGORIES = gql`
    query{
    categories {
      id
      name
      parent
      requiresContentsCard
      isFilter     
      isExamBoard
      isHigherFoundation 
      children {
        key
        categoryId
      }
    }
  }
`

export default function CategoriesCloud( { selectedCategoryId, isResultsFilter,
    higherFoundationSelection, setHigherFoundationSelection, categorySelected } ) { 

  const { loading, data } = useQuery(CATEGORIES)  
  const [resourcesContext, setResourcesContext] = useContext(ResourcesContext);
  const [categories, setCategories] = useState([]);
  const [prevSelectedCategory, setPrevSelectedCategory] = useState(null)    
  const [breadcrumbsArray] = useState([]) 
  const [pageTitle, setPageTitle] = useState(null)
  const [localHigherFoundationSelection, setLocalHigherFoundationSelection] = useState(higherFoundationSelection)

  let newPageTitle = ''
  useEffect( () => {  
    resourcesContext.breadcrumbs = breadcrumbsArray    
    resourcesContext.pageTitle = pageTitle
  } )

  useEffect( () => {
    if (setHigherFoundationSelection) {
      setHigherFoundationSelection(localHigherFoundationSelection)
    }
  }, [localHigherFoundationSelection, setHigherFoundationSelection])  
  
  useEffect( () => {   
    setResourcesContext( { ...resourcesContext, isLoading: loading} )    
  }, [loading]) 

  let categoryRowContainerClass = "categoryRowContainer"
  let selectedCategoryClass = "selectedCategory"
  let deselectedCategoryClass = "deselectedCategory"  
  let holdingTableClass = "categoryCloudContainer"

  let history = useHistory()

  const viewDocuments = (resourcesParams) => {    
    history.push('/resources' + resourcesParams.replace(/\s/g, '').toLowerCase())
  } 

  let selectedCategory = selectedCategoryId

  let preselectedCategories = []

  const categoryClicked = (category, rowIndex, colIndex) => {
    //set selectedCategoryId in context for use when switching between desktop and mobile...
    if (category) {
      resourcesContext.selectedCategoryId = category.id
    }

    //if it's got no children and is the same category ID as previously, don't do anything
    const sameBottomCategoryClicked = ((category) && (category.id === prevSelectedCategory) && (category.children.length === 0))
    if (!sameBottomCategoryClicked) {
      //if we're on the results page and we already have a HF option selected, and our selection is an exam board,
      //then we need to refresh for the new exam board, but with the same HF option selected:
      if ((isResultsFilter) && (category) && (category.isExamBoard)) {   
        if (higherFoundationSelection) {
          //need to fool the component into thinking higher/foundation is selected by setting category
          const newExamBoardCategory = category
          //check to see if exam board category has children:
          let childFound = false
          if (newExamBoardCategory.children.length > 0) {          
            //find children of exam board category:
            for (let i=0; i<newExamBoardCategory.children.length; i++) {
              //get details of each child
              const currentChild = data.categories.find(category => category.id === newExamBoardCategory.children[i].categoryId)            
              if (currentChild.name === higherFoundationSelection) {
                //we've found our corresponding HF category for this exam board - set "category" to be this corresponding HF option
                //so that we're emulating clicking on that category (for the rest of this function):
                category = currentChild
                childFound = true
              }
            }
          } 
          if (!childFound) {
            //HF category hasn't been matched, default to the first option:

            //check to see if exam board category has children:
            if (newExamBoardCategory.children.length > 0) {          
              //find the first child of the new exam board category:
              const currentChild = data.categories.find(category => category.id === newExamBoardCategory.children[0].categoryId)            
              category = currentChild            
            }
          }
        }  
        else {
          //need to fool the component into thinking higher/foundation is selected by setting category
          const newExamBoardCategory = category
          //check to see if exam board category has children:
          if (newExamBoardCategory.children.length > 0) {          
            //find the first child of the new exam board category:
            const currentChild = data.categories.find(category => category.id === newExamBoardCategory.children[0].categoryId)            
            category = currentChild            
          }
        }    
      }

      //take a copy of all the categories (that are being displayed):
      const updateArray = [...categories]

      //delete all rows after clicked row:
      const rowDiff = categories.length - (rowIndex+1)
      if (rowDiff > 0) {
        for (let i=0; i<rowDiff; i++) {
          updateArray.pop()
        }
      }

      //find sibling and reset their "selected" state to false:
      for (let i=0; i<updateArray[updateArray.length-1].length; i++) {
        updateArray[updateArray.length-1][i].selected = false
        updateArray[updateArray.length-1][i].hidden = false
      }

      //now, for the new category...
      if (category) {
        //mark the newly-clicked category as selected and visible:
        if (categorySelected) {
            categorySelected(true)
        }  
        category.selected = true
        category.hidden = false    

        //find children of selected category
        if (category.children.length > 0) {
          let workingArray = []
          for (let i=0; i<category.children.length; i++) {
            //need to take in account the category order (which is "key"):
            const arrayIndex = (category.children[i].key)-1
            workingArray[arrayIndex] = data.categories.find(element => element.id === category.children[i].categoryId)
            workingArray[arrayIndex].selected = false
            workingArray[arrayIndex].hidden = false        
          }
          if (workingArray.length > 0) {
            updateArray.push(workingArray)
          }  
          setCategories(updateArray)     
        }
        else  {          
          let resourcesParams = category.name
          //find parent and add to resourcesParams:
          let currentCat = category          
          let currentParent = currentCat.parent          
          while (currentParent !== "0") {
            currentCat = data.categories.find(element => element.id === currentParent)
            currentParent = currentCat.parent
            resourcesParams = currentCat.name + '/' +resourcesParams
          }
          resourcesParams = '/' + resourcesParams          
          
          //if we don't have any children then we need to do a fetch of sections and papers:                    
          resetSelected(updateArray)

          viewDocuments(resourcesParams)
        }    
      }
      else {        
        //no new category selected:
        setCategories([])         
      } 
    }
  }    

    

    const preselectCategory = (category, rowIndex, previousCategory) => {
      if (setHigherFoundationSelection) {
        if (category.isHigherFoundation) {
          //setHigherFoundationSelection(category.name)           
          setLocalHigherFoundationSelection(category.name)
        }
        else {
          //setHigherFoundationSelection(null)        
          setLocalHigherFoundationSelection(null)
        }
      }

      breadcrumbsArray.push( { "label": category.name, "link": category.id } )

      if (rowIndex === 0) {
        newPageTitle = category.name;
      }
      if ((rowIndex === 1) &&
        (category.name !== PAST_PAPERS_CATEGORY_NAME) && (category.name !== QS_BY_TOPIC_CATEGORY_NAME) && (category.name !== VIDEOS_CATEGORY_NAME)) {
        newPageTitle += " " + category.name;
      }
      if ((rowIndex === 2) &&
        ((previousCategory.name === PAST_PAPERS_CATEGORY_NAME) || (previousCategory.name === QS_BY_TOPIC_CATEGORY_NAME) || (previousCategory.name === VIDEOS_CATEGORY_NAME))) {
        newPageTitle += " " + category.name + " " + previousCategory.name
      }

      const updateArray = [...preselectedCategories]
  
      //delete all rows after clicked row:
      const rowDiff = preselectedCategories.length - (rowIndex+1)
      if (rowDiff > 0) {
        for (let i=0; i<rowDiff; i++) {
          updateArray.pop()
        }
      }
  
      //find sibling and reset their "selected" state to false:
      for (let i=0; i<updateArray[updateArray.length-1].length; i++) {
        updateArray[updateArray.length-1][i].selected = false
        if ((updateArray[updateArray.length-1][i].isFilter) || (!isResultsFilter)) {
          updateArray[updateArray.length-1][i].hidden = false
        }
        else {          
          updateArray[updateArray.length-1][i].hidden = true
        }
      }
      if (category) {
        category.selected = true        
  
        //find children of selected category
        if (category.children.length > 0) {
          let workingArray = []
          for (let i=0; i<category.children.length; i++) {
            //need to take in account the category order (which is "key"):
            const arrayIndex = (category.children[i].key)-1
            workingArray[arrayIndex] = data.categories.find(element => element.id === category.children[i].categoryId)                        
            workingArray[arrayIndex].selected = false
            workingArray[arrayIndex].hidden = false            
          }
          if (workingArray.length > 0) {
            updateArray.push(workingArray)
          }  
          preselectedCategories = [...updateArray]
        }
      }
  }        

  const resetSelected = (arrayToReset) => {
    if ((arrayToReset) && (arrayToReset[0])) {
      for (let i=0; i<arrayToReset[0].length; i++) {
        arrayToReset[0][i].selected = false
      }
    }
  }

  if (loading) {
    return (
        <Loader offsetY="0"/>
    )
  }
  else if (!data) {
    return <h2>No categories</h2>;
  }
  else {
      //get first tier of categories:
      if (categories.length === 0) {
        const workingArray = data.categories.filter(category => category.parent === "0")    
        //initial loading state
        categories.push(workingArray)
        //find sibling and reset their "selected" state to false:
        for (let i=0; i<categories[0].length; i++) {
          categories[0][i].selected = false        
          categories[0][i].hidden = false        
        }
      }   

      if ((selectedCategory) && (selectedCategory !== prevSelectedCategory)) {
        setPrevSelectedCategory(selectedCategory)
        //category already selected from previous page:
        const selectedCategories = []         
        let currentCategoryId = selectedCategory            
        while (currentCategoryId !== "0") {        
          const currentCategory = data.categories.find(category => category.id === currentCategoryId)
          if (currentCategory) {
            selectedCategories.push(currentCategory)
            currentCategoryId = currentCategory.parent        
          }
          else {
            currentCategoryId = "0";
          }
        }

        preselectedCategories = [...categories]          

        newPageTitle = ''
        breadcrumbsArray.length = 0
        breadcrumbsArray.push( { "label": "Home" } )            

        let previousCategory = "";
        for (let i=selectedCategories.length-1; i>=0; i--) {  
          const rowIndex = (selectedCategories.length - i) - 1
          preselectCategory(selectedCategories[i], rowIndex, previousCategory);
          previousCategory = selectedCategories[i];
        }
        setPageTitle(newPageTitle)
  
        setCategories(preselectedCategories)
        selectedCategory = null
      }
    
      return (
      <div className={ holdingTableClass }>
          {categories.map((categoryTier, rowIndex) => {
             return (           
                <div key={ rowIndex } className={ categoryRowContainerClass }>
                {categoryTier.map((category, index) => {     
                  if ((category.selected) && (!category.hidden)) {
                    return (                      
                      <div rowindex={rowIndex} key={index} data-item={category} onClick={() => categoryClicked(category, rowIndex, index)} className={ selectedCategoryClass }>
                       {category.name}
                      </div>
                    )
                  }
                  else {
                    if (!category.hidden) {
                      return (
                        <div rowindex={rowIndex} key={index} data-item={category} onClick={() => categoryClicked(category, rowIndex, index)} className={ deselectedCategoryClass }>
                        {category.name}
                        </div>
                      )
                    }
                    else {
                      return null
                    }
                  }                               
                })}
              </div>
            )
            })}            
      </div>
    )
  }
}
