import React, { useState } from "react";
import '../styles/Header.scss'
import Logo from './Logo'
import MenuButton from './MenuButton'
import NavigationBar from './NavigationBar'
import Menu from './Menu'
import { CSSTransition } from 'react-transition-group'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import YouTubeButton from './YouTubeButton'


// When the user scrolls down 20px from the top of the document, give the header a shadow
//this below function attempted to slide the header in and out. Didn't work so well on iOS:
/*var lastScrollTop = 0;

window.addEventListener('scroll', function(e) {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (windowWidth > 1080) {
        const header = document.getElementById("headerContainer")
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            header.className = "headerContainerScroll"
        }
        else {
            header.className = "headerContainer"
        }   
    }
    else {
        const newScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const header = document.getElementById("headerContainer")
        header.className = "headerContainerScroll"
    
        if (newScrollTop > lastScrollTop) {
            header.style.top = "-100px";
    
            //scrolling up...
            if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
                header.className = "headerContainer"
            }
            else {
                header.className = "headerContainer"
            }   
        }
        else {
            header.style.top = "0px";
    
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                header.className = "headerContainerScroll"
            }
            else {
                header.className = "headerContainer"
            }            
        }
        lastScrollTop = newScrollTop    
    }
 });*/

window.addEventListener('scroll', function (e) {
    const header = document.getElementById("headerContainer")
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        header.className = "headerContainerScroll"
    }
    else {
        header.className = "headerContainer"
    }
})

export default function Header({ parent }) {
    const [menuOpen, setMenuOpen] = useState(false);

    if ((menuOpen) && (parent)) {
        disableBodyScroll(parent);
    }
    else {
        enableBodyScroll(parent)
    }

    return (
        <>
            <div id="headerContainer" className="headerContainer">
                <Logo />
                <NavigationBar />

                <div className="platformButtonsContainer">  
                    <a href="https://cognitoedu.org/register" className="buttonLink">
                    <div className="signupButtonContainer" >
                        <div className="buttonItem">
                            Sign Up
                        </div>
                    </div>                
                    </a>
                    <a href="https://cognitoedu.org/login" className="buttonLink">
                    <div className="loginButtonContainer">
                        <div className="buttonItem">
                            Log In
                        </div>
                    </div>                
                    </a>                    
            </div>
        



                
                <MenuButton menuOpen={ menuOpen } setMenuOpen={ setMenuOpen }/>             
            </div>
            <CSSTransition in={ menuOpen } appear={false} timeout={300} classNames="slide" unmountOnExit={true}>
                <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
            </CSSTransition>
        </>
    )
}
