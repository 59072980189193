import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from './components/App';
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";

let pastPapersGraphQLEndpoint
if ((process.env.REACT_APP_PPGRAPHQL_URL) && (process.env.REACT_APP_PPGRAPHQL_URL !== "")) {
  pastPapersGraphQLEndpoint = process.env.REACT_APP_PPGRAPHQL_URL
}
else {  
  pastPapersGraphQLEndpoint = "http://localhost:4000/graphql"
}
const httpLink = createHttpLink({
  uri: pastPapersGraphQLEndpoint
})


const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
  document.getElementById('root')
 /* <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')*/
);
