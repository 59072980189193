import React from "react";
import '../styles/AboutUs.scss';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";
import { ConvertKitHTML } from './Subscribe';

const COGNITO_YOUTUBE_URL = 'https://www.youtube.com/cognitoedu'
const PAST_PAPERS_URL = 'https://www.cognitoresources.org';

const ourStoryHeader = 'Our Story';
const ourStoryBody = 'Cognito was founded by Amadeus (the voice) & Tom (the artist) on the belief that education plays a fundamental role in providing opportunities and improving wellbeing. As such, our aim is (and always will be) to help students achieve their potential by providing them with the highest quality resources free of charge. So far this has lead us to create a YouTube channel, a past paper website and most recently a student learning platform.';
const youtubeChannelHeader = 'YouTube Channel (2018)';
const youtubeChannelBody = 'We started our journey in 2018 with the creation of our \'<a href="' + COGNITO_YOUTUBE_URL + '" target="_blank">Cognito YouTube channel</a>\'. After a LOT of trial and improvement we now have ~400 videos covering GCSE Biology, Chemistry, Physics and Maths. We massively appreciate our viewers support, and thanks to their likes, comments, and sharing we now have almost 160k subscribers and 20 millions views.';
const youtubeImage = '/images/youtube_small.png';
const resourceSiteHeader = 'Resources Site (2020)';
const resourceSiteBody = 'One of the most important ways to revise is by practising questions from past exam papers. In 2020 we made a <a href="' + PAST_PAPERS_URL + '" target="_blank">past paper resources website</a>, that provides past papers arranged by year and past exam questions arranged by topic.';
const resourceSiteImage = '/images/resources_small.jpg';
const platformSiteHeader = 'Learning platform (2021)';
const platformSiteBody = 'Our most recent project has been a student learning platform. Our goal here was to take students through the entire learning journey, from learning material for the first time, all the way to being exam ready. We really focussed on how to make the process as efficient as possible and ensured it was specific to each exam board.';
const platformSiteImage = '/images/learning-platform-small.jpg';

const MY_FORM_ID = 1959924;

export default function AboutUs() {
    return (
        <div className="aboutUsContainer">
            <div className="aboutUsItem">
                <div className="aboutUsSectionOurStory">
                    <h1>{ ourStoryHeader }</h1>
                    <div>{ ourStoryBody }</div>
                </div>
            </div>
            <div className="aboutUsItem">
                <div className="aboutUsSectionOdd">
                    <div className="aboutUsBlockText">            
                        <h2>{ youtubeChannelHeader }</h2>
                        <div>{ parse(youtubeChannelBody) }</div>                    
                    </div>
                    <div className="aboutUsBlockImage">
                        <img className="aboutUsImage" src={ youtubeImage } alt={ youtubeChannelHeader }/>
                    </div>
                </div> 
            </div>
            <div className="aboutUsItem">
                <div className="aboutUsSectionEven">
                    <div className="aboutUsBlockText">            
                        <h2>{ resourceSiteHeader }</h2>
                        <div>{ parse(resourceSiteBody) }</div>                    
                    </div>
                    <div className="aboutUsBlockImage">
                        <img className="aboutUsImage" src={ resourceSiteImage } alt={ resourceSiteHeader }/>
                    </div>
                </div> 
            </div>
            <div className="aboutUsItem">
                <div className="aboutUsSectionOdd">
                    <div className="aboutUsBlockText">
                        <h2>{ platformSiteHeader }</h2>
                        <div>{ platformSiteBody }</div>
                    </div>
                    <div className="aboutUsBlockImage">
                        <img className="aboutUsImage" src={ platformSiteImage } alt={ platformSiteHeader }/>
                    </div>
                </div> 
            </div>
        </div>
    )
  }
  