import React, {useState } from 'react';
import '../styles/App.scss';
import { useStyles } from '../styles/app-style';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Header from './Header'
import Home from './Home'
import BackToTopButton from './BackToTopButton'
import ScrollToTop from './ScrollToTop'
import Resources from './Resources'
import Terms from './Terms'
import Footer from './Footer'
import { ResourcesContext } from './ResourcesContext'
import AboutUs from './AboutUs';
import { ThemeProvider } from '@material-ui/core/styles';
import { CognitoTheme } from '../themes/cognito-theme'
import ReactGA from 'react-ga4';

const MEASUREMENT_ID = "G-6YV9H1ZDH4";
ReactGA.initialize(MEASUREMENT_ID);

export default function App() {
  const [resourcesContext, setResourcesContext] = useState( { isLoading: true } )

  const classes = useStyles(); 
  
  return (
    <ResourcesContext.Provider value={[resourcesContext, setResourcesContext]}>
    <ThemeProvider theme={ CognitoTheme }>
    <div className="appContainer">
      <BrowserRouter>
      <ScrollToTop />
      <center>    
      <div className="mainDiv" id="mainDiv">        
        <Header parent={ App }/>
        <Switch>
          <Route exact path="/" component={ Home }/>
          <Route path="/home/:categoryId?" component={ Home }/>
          <Route path="/resources/*" component={ Resources }/>
          <Route path="/terms" component={ Terms }/>
          <Route path="/aboutus" component={ AboutUs }/>
        </Switch>
        <BackToTopButton />
      </div>
      </center>  
      <Footer hideFooter={ false } fixedFooter={ true }/>

    </BrowserRouter>
    </div>
    </ThemeProvider>
    </ResourcesContext.Provider>
  );
}
