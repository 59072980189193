import React from "react";
import '../styles/CategoryButtonsList.scss'

export default function CategoryButtonsList( { data, rowIndex, onChange } ) {    
    const categoryClicked = (categoryId, rowIndex) => {
        onChange(categoryId, rowIndex)        
    }

    return (
        <div className="categoryButtonsContainer">
            {data.map((dataItem, index) => {
                return (
                    <div key={dataItem.id} className="categoryButtonItem" onClick={() => categoryClicked(dataItem.id, rowIndex)}>
                        {dataItem.name}
                    </div>
                )
        })}
        </div>
    )
  }
  