import React, { useState, useContext } from "react";
import '../styles/Menu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import onClickOutside from "react-onclickoutside"
import '../styles/CategoriesCloud.scss'
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag'
import { useQuery } from "@apollo/react-hooks";
import { ResourcesContext } from './ResourcesContext'
import ReactGA from 'react-ga4'
import * as constants from './Constants'

const CATEGORIES = gql`
    query{
    categories {
      id
      name
      parent
      requiresContentsCard
      isFilter     
      isExamBoard
      isHigherFoundation 
      children {
        key
        categoryId
      }
    }
  }
`

function Menu({ menuOpen, setMenuOpen }) {
    const history = useHistory()
    const { loading, data } = useQuery(CATEGORIES)
    const [categories, setCategories] = useState([]);

    const [resourcesContext] = useContext(ResourcesContext);

    const homeOnClick = () => {
        categoryClicked()
    }

    const loginOnClick = () => {
        window.location.href = "https://cognitoedu.org/login";
    }

    const signupOnClick = () => {
        window.location.href = "https://cognitoedu.org/register";
    }

    const viewDocuments = (resourcesParams, selectedCategoryId) => {
        setMenuOpen(false)
        if (resourcesContext.resultsCategoryId !== selectedCategoryId) {
            history.push('/resources' + resourcesParams.replace(/\s/g, '').toLowerCase())
        }
    }

    const categoryClicked = (category, rowIndex, colIndex) => {
        if (!category) {
            //home clicked, just reset the categories list and let the render reset the display
            setCategories([])
        }
        else {
            if (category.children.length === 0) {
                //bottom category selected, go to results page...

                let resourcesParams = category.name
                //find parent and add to resourcesParams:
                let currentCat = category
                let currentParent = currentCat.parent
                while (currentParent !== "0") {
                    currentCat = data.categories.find(element => element.id === currentParent)
                    currentParent = currentCat.parent
                    resourcesParams = currentCat.name + '/' + resourcesParams
                }
                resourcesParams = '/' + resourcesParams

                //close menu then view results...
                setMenuOpen(false)
                viewDocuments(resourcesParams, category.id)
            }
            else {
                //take a copy of all the categories (that are being displayed):
                const updateArray = [...categories]

                //delete all rows after clicked row:
                const rowDiff = categories.length - (rowIndex + 1)
                if (rowDiff > 0) {
                    for (let i = 0; i < rowDiff; i++) {
                        updateArray.pop()
                    }
                }

                //find sibling and reset their "selected" state to false and mark them as hidden:
                for (let i = 0; i < updateArray[updateArray.length - 1].length; i++) {
                    updateArray[updateArray.length - 1][i].selected = false
                    updateArray[updateArray.length - 1][i].hidden = true
                }

                //mark the currently selected category as selected and visible:
                category.selected = true
                category.hidden = false

                //find children of selected category and show them:
                let workingArray = []
                for (let i = 0; i < category.children.length; i++) {
                    //need to take in account the category order (which is "key"):
                    const arrayIndex = (category.children[i].key) - 1
                    workingArray[arrayIndex] = data.categories.find(element => element.id === category.children[i].categoryId)
                    workingArray[arrayIndex].selected = false
                    workingArray[arrayIndex].hidden = false
                }
                if (workingArray.length > 0) {
                    updateArray.push(workingArray)
                }
                setCategories(updateArray)
            }
        }
    }


    Menu.handleClickOutside = () => setMenuOpen(false);

    const handleStudyMindClick = () => {
        ReactGA.event("study_mind_referral", { category: 'referral', type: "resources" });
        window.open(constants.STUDY_MIND_LINK_5, '_blank');
    }    

    if (loading) {
        return null
    }
    else if (!data) {
        return null;
    }
    else {
        //get first tier of categories:
        if (categories.length === 0) {
            const workingArray = data.categories.filter(category => category.parent === "0")
            //initial loading state
            categories.push(workingArray)
            //find sibling and reset their "selected" state to false:
            for (let i = 0; i < categories[0].length; i++) {
                categories[0][i].selected = false
                categories[0][i].hidden = false
            }
        }

        return (
            <div className="menu">
                <div className="menuCloseButton" onClick={() => setMenuOpen(false)}><FontAwesomeIcon icon={faTimes} /></div>
                <table className="menuHoldingTableClass"><tbody>
                    <tr><td><div className="menuCategoryRowContainer"><div onClick={() => homeOnClick()} className="home">Home</div></div></td></tr>

                    {categories.map((categoryTier, rowIndex) => {
                        return (
                            <tr key={rowIndex}><td>
                                <div className="menuCategoryRowContainer">
                                    {categoryTier.map((category, index) => {
                                        let indentation = (rowIndex + 1) * 20

                                        if ((category.selected) && (!category.hidden)) {
                                            return (
                                                <div style={{ textIndent: indentation }} rowindex={rowIndex} key={index} data-item={category} onClick={() => categoryClicked(category, rowIndex, index)} className="menuSelectedCategory">
                                                    {category.name}
                                                </div>
                                            )
                                        }
                                        else {
                                            if (!category.hidden) {
                                                return (
                                                    <div style={{ textIndent: indentation }} rowindex={rowIndex} key={index} data-item={category} onClick={() => categoryClicked(category, rowIndex, index)} className="menuDeselectedCategory">
                                                        {category.name}
                                                    </div>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }
                                    })}
                                </div>
                            </td></tr>
                        )
                    })}
                    {/*<tr><td><div className="menuCategoryRowContainer"><div onClick={() => handleStudyMindClick()} className="home" style={{ color: "#D85888" }}>Tutoring</div></div></td></tr>*/}
                    <tr><td><div className="menuCategoryRowContainer"><div onClick={() => signupOnClick()} className="home">Sign Up</div></div></td></tr>
                    <tr><td><div className="menuCategoryRowContainer"><div onClick={() => loginOnClick()} className="home">Log In</div></div></td></tr>
                </tbody></table>
            </div>
        )
    }
}

const clickOutsideConfig = {
    handleClickOutside: () => Menu.handleClickOutside
};

export default onClickOutside(Menu, clickOutsideConfig)