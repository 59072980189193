import React from "react";
import { useLocation } from 'react-router-dom'


export default function ScrollToTop() {   
    const location = useLocation();

    React.useEffect(() => {
        document.body.scrollTop = 0; //For Safari
        document.documentElement.scrollTop = 0; //For Chrome, Firefox, IE and Opera    
    }, [location]);
    
    return null
  }
  