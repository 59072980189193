import React, { useState, useRef, useEffect } from "react";
import '../styles/NavigationSection.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4'

export default function NavigationSection( { menuItems, headerTitle, setSelection, link, studyMindLink } ) {
    const ref = useRef();

    let history = useHistory();

    const openLink = (link) => {
      history.push(link);
    }    

    const [listOpen, setListOpen] = useState(false);

    useOnClickOutside(ref, () => setListOpen(false));

    const toggleList = () => {
        if (menuItems) {
          setListOpen(!listOpen);
        }
        else {       
          if (studyMindLink) {
            ReactGA.event("study_mind_referral", { category: 'referral', type: "resources" });
            window.open(link, '_blank');
          }
          else {
            openLink(link);
          }          
        }
    }

    const optionSelected = (optionId) => {
        setListOpen(false)
        setSelection(optionId)
    }

    return (
        <div ref={ref} className="dd-wrapper">
            <div className="dd-header" onClick={() => toggleList()}>            
              { listOpen ?
                <div className="dd-header-title-selected">
                  {headerTitle}
                  { menuItems ?
                    <div className="arrowWrapper"><FontAwesomeIcon size="sm" icon={faAngleUp} /></div>
                    : null
                  }
                </div>
                :
                <div className="dd-header-title"
                  style={ studyMindLink ? { color: "#D85888" } : null }
                >
                  {headerTitle}
                  { menuItems ?
                    <div className="arrowWrapper"><FontAwesomeIcon size="sm" icon={faAngleDown} /></div>
                    : null
                  }
                </div>
              }
            </div>
            {listOpen && <ul className="dd-list">
                {menuItems.map((menuItem, index) => {
                    return (
                        <li className="dd-list-item" key={menuItem.id} onClick={() => optionSelected(menuItem)}>{menuItem.title}</li>
                    )              
                })}
            </ul>}
        </div>
    )
  }

// Hook
function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = event => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }

          handler(event);
        };
  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
  
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }
  