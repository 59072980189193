import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import '../styles/Home.scss'
import CategoriesCloud from './CategoriesCloud'
import CategorySelectionMobile from './CategorySelectionMobile'
//import IntroVideo from './IntroVideo'
import { useParams } from 'react-router-dom'
import ScrollToTop from "./ScrollToTop";
import * as constants from './Constants'
import { ResourcesContext } from './ResourcesContext'
import { Helmet } from "react-helmet"

export default function Home() {
   const [resourcesContext] = useContext(ResourcesContext)
   const [isLoading, setIsLoading] = useState(true)
   const [categorySelected, setCategorySelected] = useState()

   useEffect( () => {
      setIsLoading(resourcesContext.isLoading)      
   }, [resourcesContext.isLoading])

   function useWindowSize() {
      const [size, setSize] = useState([0, 0]);
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
    }
    
    function ShowWindowDimensions(props) {
      const [width] = useWindowSize();
      if (width < constants.DESKTOP_CATEGORY_THRESHOLD) {
         return (
            <CategorySelectionMobile categorySelected={ setCategorySelected } selectedCategoryId={ preselectedCategoryId } />
         )
      }
      else {
         return (
            <CategoriesCloud categorySelected={ setCategorySelected } selectedCategoryId={ preselectedCategoryId } isResultsFilter={ false } />
         )
      }      
   }

   //check for preselected category either in URL or context (context always wins)...
   let preselectedCategoryId

   //overridden by URL
   let { categoryId } = useParams()
   if (categoryId) {
      preselectedCategoryId = categoryId
   }
   //check resources context
   if (resourcesContext.selectedCategoryId) {
      preselectedCategoryId = resourcesContext.selectedCategoryId
   }

   let pageTitle = 'Cognito Resources - Past Papers, Mark Schemes and Resources'
   let description = 'All the past papers, mark schemes and resources you could ever need for entrance exams, KS3, GCSE, A-level and uni admissions!'
   let keywords = "cognito resources,past papers,entrance exams,ks3,gcse,a-level,uni admissions"   

   return (    
      <div className="homeContainer">
      <Helmet>
         <title>{ pageTitle }</title>
         <meta name="description" content={ description } />
         <meta name="keywords" content={ keywords } />                      
      </Helmet>          
      <ScrollToTop />
      { isLoading ? null : <h1 className="homeTitle">Welcome to Cognito Resources</h1> }
      { isLoading ? null : <div className="homeDescription">
      We have all the past papers that we could find, so select what you're looking for below to get started!
      </div> }
      { ShowWindowDimensions() }
      <br />
      </div>
   )
}
