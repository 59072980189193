import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';

const maxPercentage = 0.8;

export default function VideoPlayer( { videoURL } ) {
  const card = useRef(null);


  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);    
    return size;
  }
  const [width, height] = useWindowSize();  
  useEffect(() => {
    if (height && width) {
      const windowAspectRatio = width/height;
      
      if (windowAspectRatio > 1.78) {
        let newHeight = height * maxPercentage;
        if (newHeight > 710) {
          newHeight = 710;
        }        
        card.current.style.height = newHeight + 'px';
        card.current.style.width = ((newHeight / 9) * 16) + 'px';
      }
      else {
        let newWidth = width * maxPercentage;
        if (newWidth > 1270) {
          newWidth = 1270;
        }
        card.current.style.width = newWidth + 'px';
        card.current.style.height = ((newWidth / 16) * 9) + 'px';
      }
    }
  }, [width, height])   

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12}>
        <CardMedia ref={ card } component="iframe" image={ videoURL } style={{ margin: 'auto', border: "none" }} />        
      </Grid>          
    </Grid>          
  )
}
