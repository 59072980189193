import React from "react";
import '../styles/MenuButton.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

export default function MenuButton( { menuOpen, setMenuOpen } ) {
//    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <div onClick={toggleMenu} className="menuContainer">
            {menuOpen ? <FontAwesomeIcon className="menuButton" icon={faTimes} /> : <FontAwesomeIcon icon={faBars} /> }
        </div>
    )
  }
  