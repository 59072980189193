import React from "react";
import '../styles/Loader.scss'

export default function Loader( {offsetY} ) {
    let newOffsetY = "0"
    if (offsetY) {
        newOffsetY = offsetY
    }

    return (
        <div className="loader" style={{marginTop: newOffsetY + 'px'}}></div>
    )
  }
  