import React, { useContext } from "react";
import '../styles/Breadcrumbs.scss'
import { useHistory } from 'react-router-dom';
import { ResourcesContext } from './ResourcesContext'
import { Helmet } from "react-helmet"

export default function Breadcrumbs() {    
    const [resourcesContext] = useContext(ResourcesContext);
    let list = []
    if (resourcesContext.breadcrumbs) {
      list = resourcesContext.breadcrumbs
    }

    let history = useHistory()
  
    const followBreadcrumb = ( link ) => {
        if (link) {
          history.push('/home/' + link)
        }
        else {
          let homeAddress = ""
          if ((process.env.REACT_APP_HOME) && (process.env.REACT_APP_HOME !== "")) {
            homeAddress = process.env.REACT_APP_HOME
          }

          window.location.href = homeAddress;        
        }
    }

    let pageTitle = 'Cognito Resources - Past Papers - '
    let keywords = "cognito resources,past papers"

    return (
        <div className="breadcrumbsContainer">         
          {list.map((breadcrumb, index) => {              
              let chevron = '>'              
              if (index === 0) {
                  chevron = ''
              }
              let deselectedBreadcrumb = false
              if (index === list.length-1) {
                deselectedBreadcrumb = true
              }
              if (breadcrumb.label !== 'Home') {
                if (index > 1) {
                  pageTitle += ' ' + chevron + ' '
                }
                pageTitle += breadcrumb.label
                keywords += ',' + breadcrumb.label.toLowerCase()
              }

              return (
                  <div key={ index } className="breadcrumbsItem">
                    <Helmet>
                      <title>{ pageTitle }</title>
                      <meta name="description" content={ pageTitle } />
                      <meta name="keywords" content={ keywords } />                      
                    </Helmet>                    

                    <div className="chevron">{ chevron }</div>
                    { deselectedBreadcrumb ?
                        <div className="breadcrumbDeselected">{ breadcrumb.label }</div>
                        : <div className="breadcrumb" onClick={() => followBreadcrumb(breadcrumb.link)}>{ breadcrumb.label }</div>
                    }
                  </div>                  
              )
              })}
        </div>        
    )
  }
  