import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import gql from 'graphql-tag'
import { useLazyQuery } from "@apollo/react-hooks"
import '../styles/Cards.scss'
import '../styles/Papers.scss'
import '../styles/SectionsList.scss'
import '../styles/StudyMind.scss'
import Loader from './Loader'
import Papers from './Papers'
import Contents from './Contents'
import CategoriesCloud from './CategoriesCloud'
import Breadcrumbs from './Breadcrumbs'
import { ResourcesContext } from './ResourcesContext'
import * as constants from './Constants'
import ReactGA from 'react-ga4'

const SECTIONS = gql`
    query ($categoryId: String) {
        sections(categoryId: $categoryId) {
            id
            name
            categoryId
        }
    }
`

export default function Sections({ categoryIdProp }) {
    const [resourcesContext, setResourcesContext] = useContext(ResourcesContext);
    const [pageTitle, setPageTitle] = useState('');

    const [isMobile, setIsMobile] = useState(false);

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    const [width,] = useWindowSize();
    useEffect(() => {
        if (width) {
            if (width < constants.DESKTOP_CATEGORY_THRESHOLD) {
                setIsMobile(true)
            }
            else {
                setIsMobile(false)
            }
        }
    }, [width])

    const handleStudyMindClick = () => {
        ReactGA.event("study_mind_referral", { category: 'referral', type: "resources" });
    }

    useEffect(() => {
        if (categoryIdProp) {
            setResourcesContext({ ...resourcesContext, resultsCategoryId: categoryIdProp })
            getData({ variables: { categoryId: categoryIdProp } })
        }
    }, [categoryIdProp])

    //reset selectedCategoryId in context as it's only for use when switching between desktop and mobile:
    resourcesContext.selectedCategoryId = null

    useEffect(() => {
        if (resourcesContext.pageTitle) {
            setPageTitle(resourcesContext.pageTitle)
        }
    }, [resourcesContext.pageTitle])

    const noSectionsMessage = "Coming soon!"
    let contentsCard = true

    const [higherFoundationSelection, setHigherFoundationSelection] = useState('');

    const [getData, { loading, data }] = useLazyQuery(SECTIONS);

    if (loading) {
        return <Loader offsetY="80" />;
    }
    else if (!data) {
        return null
    }
    else {
        if (data.sections.length > 0) {
            return (
                <div id="pageContainer" className="pageContainer">
                    <Breadcrumbs />
                    <div className="container">
                        <div className="item"><div className="pageTitle">{pageTitle}</div></div>
                        <div className="item"><CategoriesCloud
                            selectedCategoryId={categoryIdProp}
                            isResultsFilter={true}
                            higherFoundationSelection={higherFoundationSelection}
                            setHigherFoundationSelection={setHigherFoundationSelection}
                        /></div>
                        {contentsCard ? <Contents sections={data.sections} /> : ""}
                        {/*<div className="item">
                            <table className="cardNoBanner">
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        <table
                                            style={{
                                                width: "calc(100% - 50px)",
                                                tableLayout: "fixed",
                                                margin: "auto",
                                            }}
                                        >
                                            <tr>
                                                {!isMobile ?
                                                    <>
                                                        <td
                                                            style={{
                                                                paddingTop: 20,
                                                                paddingBottom: 20,
                                                                width: 170,
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            <span className="studyMindDashboardCardTitle">
                                                                <table
                                                                    style={{
                                                                        width: "100%",
                                                                        tableLayout: "fixed",
                                                                        margin: "auto",
                                                                    }}
                                                                >
                                                                    <tr>
                                                                        <td>
                                                                            Looking For
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Tutoring?
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: 20,
                                                                paddingBottom: 20,
                                                                width: 1,
                                                                paddingRight: 10
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    borderLeft: "1px solid #D9D9D9",
                                                                    height: 30
                                                                }}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: 20,
                                                                paddingBottom: 20,
                                                                maxWidth: 500
                                                            }}
                                                        >
                                                            <span className="studyMindDashboardCardText">
                                                                Cognito has partnered with Study Mind! Use the code&nbsp;
                                                                <span className="studyMindDashboardCardTextHighlighted">
                                                                    COGNITO70
                                                                </span>
                                                                &nbsp;for&nbsp;
                                                                <span className="studyMindDashboardCardTextHighlighted">
                                                                    70%
                                                                </span>
                                                                &nbsp;off your first GCSE or A-level lesson with a tutor.
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingTop: 20,
                                                                paddingBottom: 20,
                                                                width: 1,
                                                                paddingLeft: 10,
                                                                paddingRight: 10
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    borderLeft: "1px solid #D9D9D9",
                                                                    height: 30
                                                                }}
                                                            >
                                                            </div>
                                                        </td>
                                                    </>
                                                    :
                                                    null
                                                }
                                                <td
                                                    style={{
                                                        paddingTop: 20,
                                                        paddingBottom: 20,
                                                        width: 200,
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    <a
                                                        href={constants.STUDY_MIND_LINK_5}
                                                        target="_blank"
                                                        style={{ margin: "auto", textDecoration: "none" }}
                                                        onClick={handleStudyMindClick}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="studyMindDashboardCardButton"
                                                            style={
                                                                !isMobile ?
                                                                    {                                                                        
                                                                        whiteSpace: "pre",
                                                                    }
                                                                    :
                                                                    null
                                                            }
                                                        >
                                                            {isMobile ?
                                                                "70% off Study Mind 1-1 Tuition"
                                                                :
                                                                "Study Mind\r\n1-1 Tuition"
                                                            }
                                                        </button>
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>

                                    </td>
                                </tr>
                            </table>
                                                        </div>*/}

                        {data.sections.map((section, index) => {
                            return (
                                <div className="item" id={section.id} key={section.id}>
                                    <table className="card"><tbody>
                                        <tr>
                                            <td className="cardTitle" key={index} data-item={section}>
                                                {section.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <center>
                                                    <Papers sectionId={section.id} />
                                                </center>
                                            </td>
                                        </tr>
                                    </tbody></table>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="pageContainer" className="pageContainer">
                    <Breadcrumbs />
                    <div className="container">
                        <div className="item"><div className="pageTitle">{pageTitle}</div></div>
                        <div className="item"><CategoriesCloud
                            selectedCategoryId={categoryIdProp}
                            isResultsFilter={true}
                            higherFoundationSelection={higherFoundationSelection}
                            setHigherFoundationSelection={setHigherFoundationSelection}
                        />
                        </div>
                        <h2>{noSectionsMessage}</h2>
                    </div>
                </div>
            )
        }
    }
}
