import React from "react";
import '../styles/Footer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faPatreon, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useHistory } from 'react-router-dom';

const COGNITO_INSTAGRAM_URL = 'https://www.instagram.com/cognitoeducation/'
const COGNITO_TWITTER_URL = 'https://twitter.com/cognitoedu'
const COGNITO_FACEBOOK_URL = 'https://www.facebook.com/cognitoeducation/'
const COGNITO_PATREON_URL = 'https://www.patreon.com/join/cognito'
const COGNITO_YOUTUBE_URL = 'https://www.youtube.com/cognitoedu'
const COGNITO_EMAIL_ADDRESS = 'HELLO@COGNITOEDU.ORG';
const MAIL_SUBJECT = "Cognito Resources website enquiry"
const TERMS_AND_PRIVACY_LINK = '/terms';
const ABOUT_US_LINK = '/aboutus';

export default function Footer( { hideFooter, fixedFooter } ) {   
    let history = useHistory()

    const openFooterLink = (link) => {
        window.open(link, "_blank")                
    }

    const openTermsAndPrivacy = () => {
        history.push(TERMS_AND_PRIVACY_LINK)
    }

    const openAboutUs = () => {
        history.push(ABOUT_US_LINK)
    }

    const mailTo = () => {
        window.location.href = 'mailto:' + COGNITO_EMAIL_ADDRESS.toLowerCase() + '?subject=' + MAIL_SUBJECT
    }
    
    let footerClassname = "footerContainer"

    return (
        <div className={footerClassname}>        
            <div className="footerSocialMediaContainer">        
                <div className="footerSocialMediaItem" onClick={ () => openFooterLink(COGNITO_INSTAGRAM_URL) }><FontAwesomeIcon className="fa" icon={ faInstagram } /></div>
                <div className="footerSocialMediaItem" onClick={ () => openFooterLink(COGNITO_TWITTER_URL) }><FontAwesomeIcon className="fa" icon={ faTwitter } /></div>
                <div className="footerSocialMediaItem" onClick={ () => openFooterLink(COGNITO_FACEBOOK_URL) }><FontAwesomeIcon className="fa" icon={ faFacebookF } /></div>
                <div className="footerSocialMediaItem" onClick={ () => openFooterLink(COGNITO_PATREON_URL) }><FontAwesomeIcon className="fa" icon={ faPatreon } /></div>
                <div className="footerSocialMediaItem" onClick={ () => openFooterLink(COGNITO_YOUTUBE_URL) }><FontAwesomeIcon className="fa" icon={ faYoutube } /></div>                        
            </div>  
            <div className="footerLegalContainer">        
                <div className="footerLegalItemNoneSelectable">© COGNITO EDUCATION 2021</div>
                <div className="footerLegalItem" onClick={ openTermsAndPrivacy }>TERMS & PRIVACY</div>
                <div className="footerLegalItem" onClick={ mailTo }>{ COGNITO_EMAIL_ADDRESS }</div>
                <div className="footerLegalItem footerAboutUsItem" onClick={ openAboutUs }>ABOUT US</div>
            </div>  
        </div>
    )
  }
  