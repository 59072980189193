import React from "react";
import '../styles/Papers.scss'
import '../styles/Cards.scss'
import '../styles/Contents.scss'

export default function Contents( {sections} ) {    
    const contentsItemClicked = (anchor) => {
        let yOffset = -80
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (windowWidth < 1080) {
            yOffset = -60
        }  
        var elmnt = document.getElementById(anchor);
        const y = elmnt.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }    

    let contentsCardClass = "contentsCardVisible"
    if (sections.length < 2) {
        contentsCardClass = "contentsCardHidden"
    }

    let contentsRowClass = "contentsRow"
    if (sections.length < 3) {
        contentsRowClass = "contentsRowMinimum"
    }

    return (
        <div className={ contentsCardClass }>
        <div className="item">
            <table className="card"><tbody>
            <tr>                    
                <td className="cardTitle">
                Contents
                </td>                                    
            </tr>
            <tr>
                <td className="contentsTableCell">
                    <div className="contentsWrapper">
                    <div className="contentsContainer">
                        {sections.map((section, index) => {                            
                            return (
                                <div key={ section.id } className={ contentsRowClass }>
                                    <div className="contentsLink" onClick={() => contentsItemClicked(section.id)}>{section.name}</div>                                                        
                                </div>
                            )
                            })}
                    </div>
                    </div>
                </td>
            </tr>
            </tbody></table>
        </div>
        </div>
    )
  }
  