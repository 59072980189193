import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag'
import { useQuery } from "@apollo/react-hooks";
import '../styles/CategorySelectionMobile.scss'
import Loader from './Loader'
import CategoryDropdownList from './CategoryDropdownList'
import CategoryButtonsList from './CategoryButtonsList'
import { ResourcesContext } from './ResourcesContext'

const CATEGORIES = gql`
    query{
    categories {
      id
      name
      parent
      requiresContentsCard
      isFilter     
      isExamBoard
      isHigherFoundation 
      children {
        key
        categoryId
      }
    }
  }
`

export default function CategorySelectionMobile( { selectedCategoryId, categorySelected } ) {       
  let history = useHistory()   
  const { loading, data } = useQuery(CATEGORIES);   
  
  const [resourcesContext, setResourcesContext] = useContext(ResourcesContext);
  const [categories, setCategories] = useState([]);
  const [prevSelectedCategory, setPrevSelectedCategory] = useState(null)

  useEffect( () => {   
    setResourcesContext( { ...resourcesContext, isLoading: loading} )    
  }, [loading]) 

  let selectedCategory = selectedCategoryId
  let preselectedCategories = []  

  const viewDocuments = (resourcesParams) => {    
    history.push('/resources' + resourcesParams.replace(/\s/g, '').toLowerCase())
  }  

  const categoryClicked = (category, rowIndex, colIndex) => {
    //set selectedCategoryId in context for use when switching between desktop and mobile...
    if (category) {
      resourcesContext.selectedCategoryId = category.id
    }
    
    //if it's got no children and is the same category ID as previously, don't do anything
    const sameBottomCategoryClicked = ((category) && (category.id === prevSelectedCategory) && (category.children.length === 0))

    if (!sameBottomCategoryClicked) {
      //take a copy of all the categories (that are being displayed):
      const updateArray = [...categories]

      //delete all rows after clicked row:
      const rowDiff = categories.length - (rowIndex+1)
      if (rowDiff > 0) {
        for (let i=0; i<rowDiff; i++) {
          updateArray.pop()
        }
      }

      //find sibling and reset their "selected" state to false:
      for (let i=0; i<updateArray[updateArray.length-1].length; i++) {
        updateArray[updateArray.length-1][i].selected = false
        updateArray[updateArray.length-1][i].hidden = false
      }

      //now, for the new category...
      if (category) {
        //mark the newly-clicked category as selected and visible:
        if (categorySelected) {
            categorySelected(true)
        }        
        category.selected = true
        category.hidden = false    

        //find children of selected category
        if (category.children.length > 0) {
          let workingArray = []
          for (let i=0; i<category.children.length; i++) {
            //need to take in account the category order (which is "key"):
            const arrayIndex = (category.children[i].key)-1
            workingArray[arrayIndex] = data.categories.find(element => element.id === category.children[i].categoryId)
            workingArray[arrayIndex].selected = false
            workingArray[arrayIndex].hidden = false        
          }
          if (workingArray.length > 0) {
            updateArray.push(workingArray)
          }  
          setCategories(updateArray)     
        }
        else  {          
          let resourcesParams = category.name
          //find parent and add to resourcesParams:
          let currentCat = category          
          let currentParent = currentCat.parent          
          while (currentParent !== "0") {
            currentCat = data.categories.find(element => element.id === currentParent)
            currentParent = currentCat.parent
            resourcesParams = currentCat.name + '/' +resourcesParams
          }
          resourcesParams = '/' + resourcesParams          
          
          //if we don't have any children then we need to do a fetch of sections and papers:                    
          resetSelected(updateArray)

          viewDocuments(resourcesParams)
        }    
      }
      else {        
        //no new category selected:
        setCategories([])         
      } 
    }
  }   

    const preselectCategory = (category, rowIndex) => {
      const updateArray = [...preselectedCategories]
  
      //delete all rows after clicked row:
      const rowDiff = preselectedCategories.length - (rowIndex+1)
      if (rowDiff > 0) {
        for (let i=0; i<rowDiff; i++) {
          updateArray.pop()
        }
      }
  
      //find sibling and reset their "selected" state to false:
      for (let i=0; i<updateArray[updateArray.length-1].length; i++) {
        updateArray[updateArray.length-1][i].selected = false
        if (updateArray[updateArray.length-1][i].isFilter) {
          updateArray[updateArray.length-1][i].hidden = false
        }
        else {          
          updateArray[updateArray.length-1][i].hidden = true
        }
      }
      if (category) {
        category.selected = true        
  
        //find children of selected category
        if (category.children.length > 0) {
          let workingArray = []
          for (let i=0; i<category.children.length; i++) {
            //need to take in account the category order (which is "key"):
            const arrayIndex = (category.children[i].key)-1
            workingArray[arrayIndex] = data.categories.find(element => element.id === category.children[i].categoryId)                        
            workingArray[arrayIndex].selected = false
            workingArray[arrayIndex].hidden = false            
          }
          if (workingArray.length > 0) {
            updateArray.push(workingArray)
          }  
          preselectedCategories = [...updateArray]
        }
        }
      }        

  const resetSelected = (arrayToReset) => {
    if ((arrayToReset) && (arrayToReset[0])) {
      for (let i=0; i<arrayToReset[0].length; i++) {
        arrayToReset[0][i].selected = false
      }
    }
  }

  if (loading) {    
    return (
        <Loader offsetY="0"/>
      )
  }
  else if (!data) {
    return <h2>No categories</h2>;
  }
  else {            
      //get first tier of categories:
      if (categories.length === 0) {
        const workingArray = data.categories.filter(category => category.parent === "0")    
        //initial loading state
        categories.push(workingArray)
        //find sibling and reset their "selected" state to false:
        for (let i=0; i<categories[0].length; i++) {
          categories[0][i].selected = false        
          categories[0][i].hidden = false        
        }
      }   

      if ((selectedCategory) && (selectedCategory !== prevSelectedCategory)) {   
        setPrevSelectedCategory(selectedCategory)
        //category already selected from previous page:
        const selectedCategories = []         
        let currentCategoryId = selectedCategory            
        while (currentCategoryId !== "0") {        
          const currentCategory = data.categories.find(category => category.id === currentCategoryId)
          selectedCategories.push(currentCategory)
          currentCategoryId = currentCategory.parent        
        }
        preselectedCategories = [...categories]
        for (let i=selectedCategories.length-1; i>=0; i--) {  
          const rowIndex = (selectedCategories.length - i) - 1
          preselectCategory(selectedCategories[i], rowIndex)
        }
  
        setCategories(preselectedCategories)
        selectedCategory = null
      }

    const CreateCategoryRow = (categories, categoryTier, rowIndex) => {
      if (rowIndex === (categories.length-1)) {
        return <CategoryButtonsList onChange={ categoryDropdownOnChange } data={ categoryTier } rowIndex={ rowIndex } />
      }
      else {
        //find selected category id for current tier:
        let selectedCategory = ''
        for (let i=0; i<categoryTier.length; i++) {
          if (categoryTier[i].selected) {
            selectedCategory = categoryTier[i].id
          }
        }
        return <CategoryDropdownList selectedCategoryId={ selectedCategory } onChange={ categoryDropdownOnChange } data={ categoryTier } rowIndex={ rowIndex } />
      }
    }

    const categoryDropdownOnChange = (categoryId, rowIndex) => {
      const selectedCat = data.categories.find(category => category.id === categoryId) 
      categoryClicked(selectedCat, rowIndex)
    }

    return (
      <div className="mobileCategoryContainer">
          {categories.map((categoryTier, rowIndex) => {
            return (       
              <div key={ rowIndex }>    
                { CreateCategoryRow(categories, categoryTier, rowIndex) }
              </div>
            )
            })}            
      </div>
    )
  }
}
