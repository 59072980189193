import React from "react";
import '../styles/Terms.scss'

export default function Terms() {
    return (
        <div className="termsContainer">
            <h1>
                Terms & Privacy
            </h1>
            <h2>Introduction</h2>
            <p>
            Welcome to Cognito. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy and copyright notice, govern Cognito’s relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.
            </p>            
            <h2>Terms of Use</h2>
            <p>
            The term ‘Cognito’ or ‘us’ or ‘we’ refers to the owner of the website, ‘Cognito Education Limited’.
            </p>
            <p>
            The term ‘Site’ refers to the website <a href="http://www.cognitoedu.org">cognitoedu.org</a>.
            </p>
            <p>
            The term ‘you’ or ‘users’ refers to the users or viewers of the Site.
            </p>
            <p>
            The use of this Site is subject to the following terms of use:
            </p>
            <ul>
            <li>
            The content of the pages of this Site is for your general information and use only. It is subject to change without notice.
            </li>
            <li>
            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this Site for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
            </li>
            <li>
            Your use of any information or materials on this Site is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this Site meet your specific requirements.
            </li>
            <li>
            This Site contains material which is owned by or licensed to us. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
            </li>
            <li>
            From time to time, this Site may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
            </li>
            </ul>            
            <h2>Privacy Policy</h2>
            <p>
            This Privacy Policy governs the manner in which Cognito collects, uses, maintains and discloses information collected from Users of the Site. This privacy policy applies to the Site and all products and services offered by Cognito.
            </p>
            <h2>Personal identification information</h2>            
            <p>
            We do not collect any person identification information of Users. 
            </p>
            <h2>Non-personal identification information</h2>
            <p>
            We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilised and other similar information.
            </p>
            <h2>Cookie Policy</h2>
            <p>
            Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
            </p>
            <h2>Social Media</h2>
            <p>
            Cognito provides links to Our social media accounts including YouTube, Instagram, Facebook and Twitter. These are held to the terms and conditions as well as the privacy policies held with each social media platform respectively.
            </p><h2>How we use collected information</h2>
            <p>
            Cognito may collect and use Users personal information for the following purposes:
            </p>
            <ul>
                <li>
                To personalise user experience. We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
                </li>
            </ul>
            <h2>How we protect your information</h2>
            <p>
            We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.
            </p>
            <h2>Sharing your personal information</h2>
            <p>
            We do not sell, trade, or rent Users personal identification information to others.
            </p>
            <h2>Changes to this privacy policy</h2>
            <p>
            Cognito has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
            </p>
            <h2>Your acceptance of these terms</h2>
            <p>
            By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
            </p>
            <h2>Copyright Notice</h2>
            <p>
            © Copyright Cognito Education Limited. All rights reserved.
            </p>
            <p>
            Any redistribution or reproduction of part or all of the contents in any form is prohibited other than printing or downloading to a local hard drive extracts for your personal and non-commercial use only.
            </p>
            <p>
            You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.
            </p>
            <p>
            Permission for distribution for educational use would normally be granted to educational institutes as long as the licence of the material in question allows this. Please contact us for further information.
            </p>
            <h2>Contacting us</h2>            
            <p>
            If you have any questions about the Terms of Use, Privacy Policy or Copyright Notice, the practices of this site, or your dealings with this site, please contact us by email or post at:
            </p>
            <p>
            Cognito Education Limited 
            </p>
            <p>
            Flat 4, 24 Montpellier Spa Road
            </p>
            <p>
            Cheltenham, GL50 1UL
            </p>
            <p>
            UK
            </p>
            <p>
            This document was last updated on 1st October 2020
            </p>
        </div>
    )
  }
  