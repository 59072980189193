import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import gql from 'graphql-tag'
import { useQuery, useMutation } from "@apollo/react-hooks"
import '../styles/Papers.scss'
import '../styles/Cards.scss'
import Loader from "./Loader"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import VideoPlayer from './VideoPlayer';
import { useStyles } from '../styles/app-style';

const PAPERS = gql`
    query ($sectionId: String) {
        papers(sectionId: $sectionId) {
            id
            name
            paper {label, path, viewCount}
            markScheme {label, path, viewCount}
            insert {label, path, viewCount}
            resource {label, path, viewCount}
            video {label, path, viewCount}            
        }
    }
`

const INCREASEVIEWCOUNT = gql`
    mutation ($id: String, $fileType: String) {
        increaseViewCount(id: $id, fileType: $fileType) {
            name
        }
    }
`

export default function Papers({ sectionId }) {
    const classes = useStyles();

    const [forceUpdate, setForceUpdate] = useState(0);

    const { loading, data } = useQuery(PAPERS, { variables: { sectionId: sectionId } });
    const [increaseViewCount] = useMutation(INCREASEVIEWCOUNT, { ignoreResults: true });

    const [videoHandler, setVideoHandler] = useState({ showVideo: false, videoURL: null })

    const [papers, setPapers] = useState(null);

    useEffect(() => {
        if (data) {
            const newPapers = [];
            for (let i = 0; i < data.papers.length; i++) {
                let isASectionHeader = false;
                let isASpacer = false;

                if (!data.papers[i].paper &&
                    !data.papers[i].markScheme &&
                    !data.papers[i].insert &&
                    !data.papers[i].resource &&
                    !data.papers[i].video) {
                    if (data.papers[i].name === "-") {
                        isASpacer = true;
                    }
                    else {
                        isASectionHeader = true;
                    }

                }

                let isEmptySection = false;
                if (isASectionHeader) {
                    //a section is empty if the following paper is a section, spacer, or there is no next paper                                    
                    if (i + 1 === data.papers.length) {
                        isEmptySection = true;
                    }
                    else {
                        let nextPaper = data.papers[i + 1];
                        if (!nextPaper.paper && !nextPaper.markScheme && !nextPaper.insert && !nextPaper.resource && !nextPaper.video) {
                            //next paper is either a spacer or another section:
                            isEmptySection = true;
                        }
                    }
                }

                if ((isASectionHeader && isEmptySection) || (newPapers.length === 0 && isASpacer)) {
                }
                else {
                    newPapers.push(
                        {
                            ...data.papers[i],
                            isASectionHeader: isASectionHeader,
                            isASpacer: isASpacer
                        }
                    );
                }
            }


            //tidy up by removing spacers and sections at end of list...                
            let firstPaperFound = false;
            const newPapersLength = newPapers.length;            
            for (let i = newPapersLength-1; i >= 0; i--) {
                if (!newPapers[i].isASectionHeader && !newPapers[i].isASpacer) {
                    firstPaperFound = true;
                }
                if (!firstPaperFound) {
                    newPapers.pop();
                }
            }

            setPapers(newPapers);
        }
    }, [data])

    const viewFile = (id, fileType, path) => {
        if (fileType === "video") {
            viewVideo(id, fileType, path);
        }
        else {
            if (path !== "*") {
                increaseViewCount({ variables: { id: id, fileType: fileType } })
                setForceUpdate(forceUpdate + 1)

                let filesLocation
                if ((process.env.REACT_APP_FILES_LOCATION) && (process.env.REACT_APP_FILES_LOCATION !== "")) {
                    filesLocation = process.env.REACT_APP_FILES_LOCATION
                }
                else {
                    filesLocation = 'http://localhost:4000'
                }

                let fileToOpen = filesLocation + path;

                window.open(fileToOpen, "_blank")
            }
        }
    }

    const viewVideo = (id, fileType, path) => {
        if (path !== "*") {
            increaseViewCount({ variables: { id: id, fileType: fileType } });
            setForceUpdate(forceUpdate + 1);
            setVideoHandler({ ...videoHandler, showVideo: true, videoURL: path });
        }
    }

    const handleVideoClose = () => {
        setVideoHandler({ ...videoHandler, showVideo: false, videoURL: null });
    }

    const videoDialog = (
        <Dialog
            maxWidth="lg"
            open={videoHandler.showVideo}
            onClose={handleVideoClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent style={{ padding: 5 }}>
                <VideoPlayer videoURL={videoHandler.videoURL} />
            </DialogContent>
        </Dialog>
    )

    if (loading) {
        return <Loader offsetY="0" />;
    }
    else if (!papers) {
        return <div className="paperName">No papers</div>;
    }
    else {
        if (papers.length > 0) {
            let actualRowCount = 0;
            return (
                <div className="papersContainer">
                    {papers.map((thisPaper, index) => {
                        let rowClassName = "cardRowEven"
                        if (index % 2 === 0)
                            rowClassName = "cardRowOdd"

                        let noOfFiles = 0
                        let fileType
                        let filePath
                        if (thisPaper.paper) {
                            noOfFiles++
                            fileType = "paper"
                            filePath = thisPaper.paper.path
                            if (filePath === "*") {
                                thisPaper.paper.isDummy = true
                            }
                        }
                        if (thisPaper.markScheme) {
                            noOfFiles++
                            fileType = "markScheme"
                            filePath = thisPaper.markScheme.path
                            if (filePath === "*") {
                                thisPaper.markScheme.isDummy = true
                            }
                        }
                        if (thisPaper.insert) {
                            noOfFiles++
                            fileType = "insert"
                            filePath = thisPaper.insert.path
                            if (filePath === "*") {
                                thisPaper.insert.isDummy = true
                            }
                        }
                        if (thisPaper.resource) {
                            noOfFiles++
                            fileType = "resource"
                            filePath = thisPaper.resource.path
                            if (filePath === "*") {
                                thisPaper.resource.isDummy = true
                            }
                        }

                        if (thisPaper.video) {
                            noOfFiles++
                            fileType = "video"
                            filePath = thisPaper.video.path
                            if (filePath === "*") {
                                thisPaper.video.isDummy = true
                            }
                        }

                        let multipleLinks = false
                        if (noOfFiles > 1) {
                            multipleLinks = true
                        }

                        let filePaperLinkClass = "singleFilePaperLink"
                        if (multipleLinks) {
                            filePaperLinkClass = "multipleFilePaperLink"
                        }
                        let filePaperLinkDisabledClass = "singleFilePaperLinkDisabled"
                        if (multipleLinks) {
                            filePaperLinkDisabledClass = "multipleFilePaperLinkDisabled"
                        }

                        return (
                            <div key={thisPaper.id} className={rowClassName}>

                                {thisPaper.isASpacer ?
                                    <div className="paperName"></div>
                                    :
                                    null
                                }

                                {thisPaper.isASectionHeader ?
                                    <div className="paperName" style={{ fontWeight: 600 }}>{thisPaper.name}</div>
                                    :
                                    null
                                }

                                {!thisPaper.isASpacer && !thisPaper.isASectionHeader ?
                                    multipleLinks ?
                                        <div className="paperName">{thisPaper.name}</div>
                                        :
                                        <div onClick={() => viewFile(thisPaper.id, fileType, filePath)} className="paperNameLink">{thisPaper.name}</div>
                                    :
                                    null
                                }

                                <div className="paperLinks">
                                    {thisPaper.paper ?
                                        <div className={thisPaper.paper.isDummy ? filePaperLinkDisabledClass : filePaperLinkClass} onClick={() => viewFile(thisPaper.id, "paper", thisPaper.paper.path)}>
                                            {thisPaper.paper ? thisPaper.paper.label || "Paper" : ""}</div>
                                        : ""}

                                    {thisPaper.markScheme ?
                                        <div className={thisPaper.markScheme.isDummy ? filePaperLinkDisabledClass : filePaperLinkClass} onClick={() => viewFile(thisPaper.id, "markScheme", thisPaper.markScheme.path)}>
                                            {thisPaper.markScheme ? thisPaper.markScheme.label || "Mark Scheme" : ""}</div>
                                        : ""}

                                    {thisPaper.insert ?
                                        <div className={thisPaper.insert.isDummy ? filePaperLinkDisabledClass : filePaperLinkClass} onClick={() => viewFile(thisPaper.id, "insert", thisPaper.insert.path)}>
                                            {thisPaper.insert ? thisPaper.insert.label || "Insert" : ""}</div>
                                        : ""}

                                    {thisPaper.resource ?
                                        <div className={thisPaper.resource.isDummy ? filePaperLinkDisabledClass : filePaperLinkClass} onClick={() => viewFile(thisPaper.id, "resource", thisPaper.resource.path)}>
                                            {thisPaper.resource ? thisPaper.resource.label || "Resource" : ""}</div>
                                        : ""}

                                    {thisPaper.video ?
                                        <div className={thisPaper.video.isDummy ? filePaperLinkDisabledClass : filePaperLinkClass} onClick={() => viewFile(thisPaper.id, "video", thisPaper.video.path)}>
                                            {thisPaper.video ? thisPaper.video.label || "Video" : ""}</div>
                                        : ""}

                                </div>
                            </div>
                        )
                    })}
                    {videoDialog}
                </div>
            )
        }
        else {
            return (
                <div className="papersContainer">
                    <div className="paperName">No papers</div>
                </div>
            );
        }
    }
}
