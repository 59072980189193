import React from "react";
import { Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from "@apollo/react-hooks";
import SectionsList from './SectionsList'

const CATEGORIES = gql`
    query{
    categories {
      id
      name
      parent
      requiresContentsCard
      isFilter     
      isExamBoard
      isHigherFoundation 
      children {
        key
        categoryId
      }
    }
  }
`

export default function Resources(props) {     
    let categoryId
    const { loading, data } = useQuery(CATEGORIES); 
    if (loading) {
        //wait
        return null
    }
    else if (!data) {
        //return home page
        categoryId = null
        return null
    }
    else {         
        const params = props.match.params;     
        const categories = params[0].split("/")
        
        let currentParentId = "0"
        let currentCategoryNameToFind
        let currentCategory
        let currentCategoryResult
        for (let i=0; i<categories.length; i++) {
            currentCategoryNameToFind = categories[i]
            currentCategoryResult = data.categories.filter(element => {
                return ((element.name.replace(/\s/g, '').toLowerCase() === currentCategoryNameToFind.replace(/\s/g, '').toLowerCase()) &&
                    (element.parent === currentParentId))
            })

            if ((currentCategoryResult) && (currentCategoryResult[0])) {
                currentCategory = currentCategoryResult[0]
            }
            else {
                currentCategory = null
            }

            if (currentCategory) {            
                currentParentId = currentCategory.id
                categoryId = currentCategory.id
            }
            else {
                categoryId = null
                i = categories.length
            }
        }
        
        if ((!currentCategory) || (currentCategory.children.length > 0)) {
            categoryId = null
        }
    }

    if (categoryId) {
        return (
            <SectionsList categoryIdProp={ categoryId } />        
        )        
    }
    else {
        return (
            <Redirect to='/home' />
        )
    }
}
  