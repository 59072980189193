import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from 'react-router-dom';
import '../styles/NavigationBar.scss'
import NavigationSection from './NavigationSection'
import gql from 'graphql-tag'
import { useQuery } from "@apollo/react-hooks";
import { ResourcesContext } from './ResourcesContext'
import { PAST_PAPERS_CATEGORY_NAME, QS_BY_TOPIC_CATEGORY_NAME, STUDY_MIND_LINK_5, VIDEOS_CATEGORY_NAME } from './Constants';

const ABOUT_US_LINK = '/aboutus';

const CATEGORIES = gql`
    query{
    categories {
      id
      name
      parent
      requiresContentsCard
      isFilter     
      isExamBoard
      isHigherFoundation 
      children {
        key
        categoryId
      }
    }
  }
`


export default function NavigationBar() {
  const [resourcesContext, setResourcesContext] = useContext(ResourcesContext);   

  const [redirect, setRedirect] = useState(null);

  const optionSelected = (menuItem) => {
    if (menuItem.hasChildren) {
      resourcesContext.selectedCategoryId = menuItem.id;
      setRedirect('/home');
    }
    else {
      setRedirect('/resources/' + menuItem.path);
    }
  }     

  useEffect(() => {
    if (redirect) {
      setRedirect(null);
    }    
  }, [redirect])

  const { loading, data } = useQuery(CATEGORIES); 
  if (loading) {
    return <div></div>
  }
  else if (!data) {
    return null
  }
  else {
    if (redirect) {
      return (
        <Redirect to={ { pathname: redirect } } />
      )
    }
    else {      
      const menu = [];

      const pastPapersMenuHeader = 'Past Papers';
      const qsByTopicMenuHeader = 'Exam Qs by Topic';
      const videosMenuHeader = 'Videos';

      const pastPapersMenuList = [];
      const qsByTopicMenuList = [];  
      const videosMenuList = [];  
      
      const findCategoryById = (categoryId) => {        
        return data.categories.filter(category => category.id === categoryId)[0];        
      }

      //go through the first level of menus to see if
      const menuHeaders = data.categories.filter(category => category.parent === "0") 
      for (let i=0; i<menuHeaders.length; i++) {
        const parentName = menuHeaders[i].name;

        let pastPapersMenuItemName;
        let pastPapersMenuItemId;
        let qsByTopicMenuItemName;
        let qsByTopicMenuItemId;
        let videosMenuItemName;
        let videosMenuItemId;

        let pastPapersChild;
        let qsByTopicChild;          
        let videosChild;      

        for (let j=0; j<menuHeaders[i].children.length; j++) {
          let child = findCategoryById(menuHeaders[i].children[j].categoryId);
          child.path = parentName + '/' + child.name;
          if (child) {
            if (child.name === PAST_PAPERS_CATEGORY_NAME) {
              pastPapersChild = child;
            }
            if (child.name === QS_BY_TOPIC_CATEGORY_NAME) {
              qsByTopicChild = child;            
            }          
            if (child.name === VIDEOS_CATEGORY_NAME) {
              videosChild = child;
            }     
          }
        }     

        if (!pastPapersChild && !qsByTopicChild && !videosChild) {
          //no 2nd tier for past papers, qs by topic and videos
          pastPapersMenuItemName = menuHeaders[i].name;
          pastPapersMenuItemId = menuHeaders[i].id;            
        }
        else {
          //2nd tier with at least one of past papers, qs by topic and videos
          if (pastPapersChild) {
            pastPapersMenuItemName = menuHeaders[i].name;
            pastPapersMenuItemId = pastPapersChild.id;     
          }
          if (qsByTopicChild) {
            qsByTopicMenuItemName = menuHeaders[i].name;
            qsByTopicMenuItemId = qsByTopicChild.id;     
          } 
          if (videosChild) {            
            const videosChildren = data.categories.filter(category => category.parent === videosChild.id);
            for (const videoChild of videosChildren) {
              if (videoChild.children && videoChild.children.length > 0) {
                videoChild.path = null;
                videoChild.hasChildren = true;
              }
              else {
                videoChild.path = (videosChild.path + '/' + videoChild.name).toLowerCase();
                videoChild.hasChildren = false;
              }
 
              videosMenuList.push( { key: videosMenuHeader, title: pastPapersMenuItemName + ' ' + videoChild.name,
                id: videoChild.id, hasChildren: videoChild.hasChildren, path: videoChild.path } );
            }    
          }
        }
        
        if ((pastPapersMenuItemName) && (pastPapersMenuItemId)) {
          pastPapersMenuList.push( { key: pastPapersMenuHeader, title: pastPapersMenuItemName, id: pastPapersMenuItemId, hasChildren: true, path: null } );
        }
        if ((qsByTopicMenuItemName) && (qsByTopicMenuItemId)) {
          qsByTopicMenuList.push( { key: qsByTopicMenuHeader, title: pastPapersMenuItemName, id: qsByTopicMenuItemId, hasChildren: true, path: null } );
        }        
      }

      menu.push( {
        name: pastPapersMenuHeader,
        options: pastPapersMenuList
      } )      

      menu.push( {
        name: qsByTopicMenuHeader,
        options: qsByTopicMenuList
      } ) 
      
      menu.push( {
        name: videosMenuHeader,
        options: videosMenuList
      } )       

      menu.push( {
        name: 'About Us',
        link: ABOUT_US_LINK
      })

      /*menu.push( {
        name: 'Tutoring',
        link: STUDY_MIND_LINK_5,
        studyMindLink: true
      })*/

      return (
        <div className="navigationBarTable">                          
              {menu.map((menuItem, index) => {  
                return (
                  <NavigationSection
                    key={ index }
                    setSelection={optionSelected}
                    menuItems={ menuItem.options }
                    headerTitle={ menuItem.name }
                    link={ menuItem.link }
                    studyMindLink={ menuItem.studyMindLink }
                    />             
                )
              } ) }                        
        </div>
      )
    }    
  }
}
