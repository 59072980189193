import React from "react";
import '../styles/CategoryDropdownList.scss'
import Select from 'react-select'

export default function CategoryDropdownList( { data, rowIndex, onChange, selectedCategoryId } ) {    
    const selectChanged = (selectedOption) => {
        onChange(selectedOption.value, rowIndex)        
    }

    let selectedCategory
    const options = []
    for (const dataItem of data) {              
        options.push( { value: dataItem.id, label: dataItem.name } )
        if (selectedCategoryId === dataItem.id) {
            selectedCategory = options[options.length-1]
        }
    }

    return (
        <Select
            className="categoryDropdownList"
            classNamePrefix="select"
            onChange={ (e) => selectChanged(e) }
            options={ options }
            value={ selectedCategory }
            isClearable={ false }
            isSearchable={ false }
            selectedOption={ selectedCategoryId }
        />
    )
  }
  